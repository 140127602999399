import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import BaseInput from "@/components/Base/BaseInput.vue";
import BaseMessageError from "@/components/Base/BaseMessageError.vue";
import BaseRadio from "@/components/Base/BaseRadio.vue";
import BaseSelect from "@/components/Base/BaseSelect.vue";
import { indexOfClass } from "@/helpers";
import { helperMixin, twoStateBindValue } from "@/mixins";
import { defineComponent, ref } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import BaseFileInput from "@/components/Base/BaseFileInput.vue";
export default defineComponent({
    emits: ["select-change", "add-button-click"],
    mixins: [helperMixin, twoStateBindValue],
    components: {
        BaseFileInput,
        VueDatePicker,
        BaseMessageError,
        BaseCheckbox,
        BaseRadio,
        BaseInput,
        BaseSelect,
    },
    props: {
        selectPlaceholder: String,
        defaultFile: String,
        fileToBase64: {
            default: false,
        },
        inputClass: {
            default: "",
        },
        maxDate: {
            default: "",
        },
        minDate: {
            default: "",
        },
        withSelectAddButton: {
            type: Boolean,
            default: false,
        },
        removedSelectedItem: {
            type: Function,
            required: false,
        },
        selectKeyValue: {
            type: String,
            default: "value",
        },
        selectAbsolutePosition: {
            type: Boolean,
            default: true,
        },
        selectKeyText: {
            type: String,
            default: "text",
        },
        customClassLabel: {
            type: String,
            default: "",
        },
        stickyLabel: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        inputType: {
            type: String,
            default: "text",
        },
        label: {
            type: String,
            default: "",
        },
        invalidMessagePosition: {
            type: String,
            default: "afterField",
        },
        invalidMessage: {
            type: Array,
            default: () => [],
        },
        /** item for checkbox/radio/select input */
        items: {
            type: Array,
            default: () => [],
        },
        filterPaginate: {
            type: Object,
            default: null,
        },
        resourceHttp: {
            type: Object,
            default: null,
        },
    },
    setup() {
        return {
            root: ref(null),
            messageError: ref(null),
        };
    },
    computed: {
        indexMessageInForm() {
            var _a, _b;
            return indexOfClass((_b = (_a = this.root) === null || _a === void 0 ? void 0 : _a.closest("body")) === null || _b === void 0 ? void 0 : _b.getElementsByClassName(this.messageError.$el.className), this.messageError.$el);
        },
        errorMessage() {
            var _a, _b;
            return (_b = (_a = this.invalidMessage) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : "";
        },
    },
    mounted() {
        var _a, _b;
        if (this.inputType === "radio") {
            this.inputValue =
                (_a = this.modelValue) !== null && _a !== void 0 ? _a : (_b = this.items.find((item) => item.default)) === null || _b === void 0 ? void 0 : _b.value;
        }
        else if (this.inputType === "limit") {
            this.inputValue = this.modelValue;
        }
        else if (this.inputType === "price") {
            this.inputValue = this.numberFormat(this.inputValue);
        }
    },
    watch: {
        errorMessage(val) {
            this.$nextTick(() => {
                var _a;
                if (val && this.indexMessageInForm === 0) {
                    (_a = this.root) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            });
        },
    },
    methods: {
        setLimit(act) {
            if (!this.inputValue) {
                this.inputValue = 0;
            }
            if (act == "increase") {
                this.inputValue++;
            }
            else {
                this.inputValue--;
            }
        },
        resetSelect() {
            var _a, _b;
            (_b = (_a = this.$refs) === null || _a === void 0 ? void 0 : _a.select) === null || _b === void 0 ? void 0 : _b.reset();
        },
    },
});
