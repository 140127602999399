import { defineComponent } from "vue";
import { useRouter } from "@/lib/router";
import SideMenu from "@/components/App/SideMenu.vue";
import template from "@/template/app";
import TopMenu from "@/components/App/TopMenu.vue";
import Content from "@/components/App/Content.vue";
import { helperMixin } from "@/mixins";
import { RouteNames } from "@/enums";
import { checkPermissionMenu } from "@/helpers";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import SubscriptionReminder from "@/components/SubscriptionReminder.vue";
import FloatCustomerService from "@/components/FloatCustomerService.vue";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        FloatCustomerService,
        SubscriptionReminder,
        BaseButtonPrimary,
        SideMenu,
        TopMenu,
        Content,
    },
    async mounted() {
        this.setPageTitle();
        this.appStore.state.loadingRoute = true;
        await this.appStore.fetchMenus();
        await this.checkUserRole();
        await this.setActiveMenu();
        await this.$nextTick();
        this.appStore.state.loadingRoute = false;
        useRouter().afterEach(() => {
            this.appStore.state.loadingRoute = false;
        });
        template();
        this.appStore.state.loadingPage = false;
    },
    data() {
        return {
            checkingRole: true,
        };
    },
    methods: {
        async setActiveMenu() {
            this.appStore.state.activeMenu = null;
            await this.$nextTick();
            this.appStore.state.activeMenu = this.findActiveMenuByCurrentRoute(this.appStore.state.menus);
            await this.$nextTick();
            if (this.appStore.state.activeMenu &&
                !checkPermissionMenu(this.appStore.state.activeMenu))
                await this.useRouter().push({ name: this.RouteNames.Dashboard });
        },
        findActiveMenuByCurrentRoute(menus) {
            var _a, _b, _c;
            for (let i = 0; i < menus.length; i++) {
                if ((menus[i].vue_router_name === this.currentRoute.name ||
                    menus[i].vue_router_name === ((_a = this.currentRoute.meta) === null || _a === void 0 ? void 0 : _a.rootName)) &&
                    !((_b = menus[i].menus) === null || _b === void 0 ? void 0 : _b.length)) {
                    return menus[i];
                }
                let foundInChilds = this.findActiveMenuByCurrentRoute(((_c = menus[i].menus) !== null && _c !== void 0 ? _c : []));
                if (foundInChilds)
                    return foundInChilds;
            }
        },
        async checkUserRole() {
            var _a, _b;
            this.checkingRole = true;
            if (((_a = this.currentRoute.meta) === null || _a === void 0 ? void 0 : _a.userRole) &&
                ((_b = this.currentRoute.meta) === null || _b === void 0 ? void 0 : _b.userRole) !== this.authStore.state.user.role) {
                await useRouter().push({
                    name: RouteNames.Dashboard,
                });
            }
            this.checkingRole = false;
        },
    },
    watch: {
        async currentRoute() {
            this.setActiveMenu();
        },
    },
});
