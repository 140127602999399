import { createInstance } from '@/lib/fn';
import BaseResourceHttp from '@/lib/resourceHttp';
class CrewBoard extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["delete"];
    }
    baseURL() {
        return "crew_board";
    }
    multiSave(data) {
        return this.request("POST", this.url("multi_save"), {
            data
        });
    }
    async exportPlanning(filter) {
        const { response, headers, status } = await this.request("POST", this.url("export_planning"), {
            responseType: "arraybuffer",
            data: filter,
        });
        if (status === 500) {
            throw "INTERNAL SERVER ERROR";
        }
        const blob = new Blob([response], { type: headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "planning_" + new Date().toISOString() + ".xlsx";
        link.click();
        window.URL.revokeObjectURL(link.href);
    }
    async paginate(filter) {
        return super.paginate({
            ...filter,
        });
    }
    async historyByUser(filter) {
        var _a;
        const { response, status, message } = await this.request("GET", this.url(`history_by_user`), {
            params: filter,
        });
        return {
            message,
            status,
            data: (_a = response.data) !== null && _a !== void 0 ? _a : [],
            has_more: response.has_more,
            total: response.total,
        };
    }
}
export const crewBoardHttp = createInstance(CrewBoard);
export default crewBoardHttp;
