import { RouteNames } from "@/enums";
import { createBaseChildrenRecordRaw } from "@/lib/fn";
export default createBaseChildrenRecordRaw('template', {
    index: {
        name: RouteNames.Template,
        component: () => import("@/pages/Superadmin/Template/Index.vue"),
    },
    create: {
        name: RouteNames.TemplateCreate,
        component: () => import("@/pages/Superadmin/Template/Save.vue"),
    },
    edit: {
        name: RouteNames.TemplateEdit,
        component: () => import("@/pages/Superadmin/Template/Save.vue"),
    }
});
