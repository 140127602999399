import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import { helperMixin } from "@/mixins";
import { hideModal, onModalHide, onModalShow, showModal } from "@/template/app";
import { defineComponent, ref } from "vue";
export default defineComponent({
    mixins: [helperMixin],
    emits: ["hide", "show", "update:modelValue", "save"],
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        sm: {
            type: Boolean,
            default: false,
        },
        lg: {
            type: Boolean,
            default: false,
        },
        xl: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    components: { BaseButtonPrimary, BaseButtonDefault },
    setup() {
        const modal = ref();
        return {
            modal,
        };
    },
    mounted() {
        onModalShow(this.modal, () => {
            this.$emit("show");
        });
        onModalHide(this.modal, () => {
            this.$emit("update:modelValue", false);
        });
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(val) {
                this.$nextTick(() => {
                    if (val) {
                        showModal(this.modal);
                    }
                    else {
                        hideModal(this.modal);
                        this.$emit("hide");
                    }
                });
            },
        },
    },
});
