import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class TemplateHttp extends BaseResourceHttp {
    baseURL() {
        return "template_setting";
    }
    getVariables() {
        return this.request("GET", this.url("variables"));
    }
    setActive(id) {
        return this.request("PUT", this.url(`set_active/${id}`));
    }
    reportLink() {
        return `${this.apiURL}report_email`;
    }
}
export const templateHttp = createInstance(TemplateHttp);
