import app from "@/http/app";
import { createInstance } from "@/lib/fn";
import Store from "@/lib/store";
class App extends Store {
    states() {
        return {
            appName: process.env.VUE_APP_NAME,
            contentHeaderCustomClass: "",
            currKey: 0,
            loadingRoute: false,
            loadingPage: true,
            menus: [],
            activeMenu: null,
            lang: "en",
            translations: null,
            error: {
                isTrue: false,
                code: 404,
                message: "Page not found",
            },
        };
    }
    constructor() {
        super();
        window.addEventListener("keydown", () => {
            this.state.currKey = 1;
        });
        window.addEventListener("keyup", () => {
            this.state.currKey = 0;
        });
    }
    setError(msg, code) {
        this.state.error.isTrue = true;
        this.state.error.message = msg;
        this.state.error.code = code;
    }
    async fetchConfig() {
        const { lang, translations } = await app().getTranslations();
        this.state.lang = lang;
        this.state.translations = translations;
    }
    async fetchMenus() {
        this.state.menus = await app().menus();
    }
}
export const appStore = createInstance(App);
export default appStore;
