import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import { baseModal, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
export default defineComponent({
    emits: ["yes"],
    props: {
        customYes: {
            required: false,
        },
        type: {
            type: String,
            default: "default"
        }
    },
    mixins: [helperMixin, baseModal],
    components: {
        BaseModal,
        BaseButtonDefault,
        BaseButtonDanger,
        BaseButtonPrimary
    },
    methods: {
        yes() {
            if (!this.customYes) {
                this.$emit('yes');
                this.value = false;
            }
            else if (this.customYes instanceof Function) {
                this.customYes();
            }
        }
    }
});
