import TopMenuDropdown from "@/components/App/TopMenuDropdown.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import CompanyLogo from "@/components/CompanyLogo.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import AvatarImg from "@/components/AvatarImg.vue";
import NavItemPlanningReminder from "@/components/App/NavItemPlanningReminder.vue";
import NavItemNotification from "@/components/App/NavItemNotification.vue";
import NavItemRescheduleRequest from "@/components/App/NavItemRescheduleRequest.vue";
import auth from "@/http/auth";
import NavItemChat from "@/components/App/NavItemChat.vue";
export default defineComponent({
    mixins: [helperMixin],
    components: {
        NavItemChat,
        TopMenuDropdown,
        CompanyLogo,
        BaseLink,
        AvatarImg,
        NavItemPlanningReminder,
        NavItemNotification,
        NavItemRescheduleRequest,
    },
    methods: {
        async logout() {
            try {
                this.appStore.state.loadingPage = true;
                const { status } = await auth().logout();
                this.appStore.state.loadingPage = false;
                if (status === 200) {
                    this.authStore.revokeToken();
                }
            }
            catch (error) {
            }
        },
    },
});
